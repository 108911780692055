/**
 * @file validationSchema.ts Yup validation schema for Campaigns
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";
import { AntiSpoofingType } from "../../../services/campaignService";
import { createYupDisplayName, yupTrimmedString } from "../../../utils/validationUtils";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  name: yupTrimmedString("Name")
    .min(2, "Name cannot be shorter than ${min} characters")
    .max(100, "Name cannot be longer than ${max} characters")
    .required("Required"),
  desc: yupTrimmedString("Description")
    .max(500, "Description cannot be longer than ${max} characters")
    .required("Required"),
  reference_campaign: Yup.boolean()
    .required(),
  display_name: Yup.string()
    .when("reference_campaign", {
      is: false,
      then: createYupDisplayName("Display Name"),
    }),
  campaign_anti_spoofing_enabled: Yup.boolean()
    .required(),
  anti_spoofing: Yup.string()
    .when("campaign_anti_spoofing_enabled", {
      is: true,
      then: Yup.string()
      .test("anti-spoofing-check", function (value) {
        // Extract a flag or key from the context to decide which test to run.
        const { testType } = this.options.context || {};
        // If context indicates we should perform the disabled check:
        if (testType === "bulkUpload") {
          if (value !== "blocking" && value !== "no enrichment") {
            return this.createError({
              message: "Invalid anti-spoofing type"
            });
          }

        } else {
          if (value === AntiSpoofingType.DISABLED) {
            return this.createError({
              message: "Please select the anti-spoofing type"
            });
          } 
        }
        return true;
      })
        .required()
    })
});

