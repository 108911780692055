/**
 * @file CampaignsTable.tsx CampaignsTable component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useNavigate, useLocation } from "react-router-dom";
import campaignService, { AntiSpoofingType, CampaignType, antiSpoofingTypeToDisplayString } from "../../../services/campaignService";
import { BrandType } from "../../../services/brandService";
import { DataGrid, GridCellParams, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useState } from "react";
import useStyles, { sxProps } from "./styles";
import SearchFieldToolbar from "../../common/DataGrid/SearchFieldToolbar";
import { MSISDNType } from "../../../services/msisdnService";
import { useMutation, useQueryClient } from "react-query";
import { invalidateCampaignQueries, invalidateMsisdnQueries } from "../../../utils/invalidateQueries";
import { Typography } from "@mui/material";
import { useUserInfo } from "../../../hooks/useUserInfo";
import { alertDialogTitle, alertDialogContent } from "../../../utils/utils";

interface Props {
  data: CampaignType[];
  brand: BrandType;
  msisdns: MSISDNType[];
  setSuccessMessage?: (s: string) => void;
  setErrorMessage?: (s: string) => void;
  // can be used to hold the selected campaigns outside of this component
  setCampaignSelection?: (s: GridRowSelectionModel) => void;
}

/**
 * Renders CampaignsTable component
 * @param props component props @see Props
 * @returns {React.Component} CampaignsTable component
 */
export default function CampaignsTable(props: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useUserInfo();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  // setCampaignSelection means we are dealing with a read only version of the table.
  const readOnly: boolean = !!props.setCampaignSelection;

  const queryClient = useQueryClient();
  const { mutateAsync: deleteCampaign } = useMutation((ids: string[]) => 
    Promise.all(ids.map(campaignService.delete)), {
    onSuccess: (results) => {
      props.setSuccessMessage!("Deleted " + results.length + " Campaign(s)!");
    },
    onSettled: () => {
      invalidateCampaignQueries(queryClient);
      invalidateMsisdnQueries(queryClient);
    }
  });

  /**
   * Navigates to relevant brand based on role/current location
   */
  const handleClick = (id: string) => {
    if (location.pathname.includes("campaigns")) {
      navigate(location.pathname + "/" + id);
    } else if (location.pathname.includes("msisdns")) {
      navigate(location.pathname + "/../campaigns/" + id);
    } else {
      navigate(location.pathname + "/campaigns/" + id);
    }
  };

  if (props.data.length === 0) return <Typography marginTop="1em">No Campaigns</Typography>;

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 380,
      // We need this to add the desired style to the column,
      // which is to show the text in two different colors for Reference Campaigns.
      renderCell: (params) => {
        return params.row.referenceCampaign ? 
          <Typography className={classes.renderCellDiv} color="grey">{params.row.name}
            <Typography color="red" display={'inline'}> (Reference Campaign) </Typography>
          </Typography>
          : params.row.name;
      }
    },
    { field: 'displayName', headerName: 'Display Name', width: 250 },
    { field: 'antiSpoofing', headerName: 'Anti-Spoofing', width: 250 },
    { field: 'assignedNumbers', headerName: 'Assigned Numbers', width: 145, align: 'right' }
  ];

  const noDisplayName = "No display name";

  const rows = props.data.map((campaign) => ({
    id: campaign.id,
    name: campaign.name,
    displayName: campaign.display_name || noDisplayName,
    referenceCampaign: campaign.display_name ? false : true,
    assignedNumbers: props.msisdns.filter((msisdn) => msisdn.campaign_id === campaign.id).length,
    antiSpoofing: antiSpoofingTypeToDisplayString(AntiSpoofingType[campaign.anti_spoofing as keyof typeof AntiSpoofingType]),
  }));

  const canDelete: boolean = role === "vodafone-admin" || role === "partner-manager" || role === "brand-manager";

  return (
    <DataGrid className={classes.datagrid}
      // Style for search toolbar
      sx={sxProps.datagridToolbar}
      disableColumnMenu
      disableRowSelectionOnClick
      autoHeight
      checkboxSelection={canDelete || readOnly}
      rows={rows}
      columns={columns}
      // color "no display name" cells
      getCellClassName={(params: GridCellParams<any, any, any>) => {
        return params.field === 'displayName' && params.row.referenceCampaign === true ? 'color' : '';
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            // Hide anti-spoofing column when feature is disabled
            antiSpoofing: props.brand.feature_anti_spoofing_enabled == true,
          },
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
        if (props.setCampaignSelection) {
          props.setCampaignSelection(newRowSelectionModel);
        }
      }}
      onRowClick={(row) => !readOnly && handleClick(row?.id as string)}
      slots={{
        toolbar: SearchFieldToolbar,
      }}
      slotProps={{
        toolbar: {
          alertDialogTitle: alertDialogTitle("Campaign", rowSelectionModel.length),
          alertDialogContent: alertDialogContent("Campaign", rowSelectionModel.length),
          rowSelection: rowSelectionModel,
          deleteFunction: canDelete && !readOnly ? deleteCampaign : undefined,
          setErrorMessage: props.setErrorMessage,
        },
      }}
    />
  );
}
